/**
 * @license
 *
 * Font Family: Switzer
 * Designed by: Jérémie Hornus
 * URL: https://www.fontshare.com/fonts/switzer
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Switzer Variable(Variable font)
 * Switzer Variable Italic(Variable font)
 * Switzer Thin
 * Switzer Thin Italic
 * Switzer Extralight
 * Switzer Extralight Italic
 * Switzer Light
 * Switzer Light Italic
 * Switzer Regular
 * Switzer Italic
 * Switzer Medium
 * Switzer Medium Italic
 * Switzer Semibold
 * Switzer Semibold Italic
 * Switzer Bold
 * Switzer Bold Italic
 * Switzer Extrabold
 * Switzer Extrabold Italic
 * Switzer Black
 * Switzer Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 400.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-Variable.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Variable.woff') format('woff'),
  url('/assets/fonts/Switzer-Variable.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 400.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-VariableItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-VariableItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-VariableItalic.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-Thin.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Thin.woff') format('woff'),
  url('/assets/fonts/Switzer-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-ThinItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-ThinItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-Extralight.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Extralight.woff') format('woff'),
  url('/assets/fonts/Switzer-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-ExtralightItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-ExtralightItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-Light.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Light.woff') format('woff'),
  url('/assets/fonts/Switzer-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-LightItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-LightItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-Regular.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Regular.woff') format('woff'),
  url('/assets/fonts/Switzer-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer';
  src: url('/assets/fonts/Switzer-Italic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Italic.woff') format('woff'),
  url('/assets/fonts/Switzer-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Medium';
  src: url('/assets/fonts/Switzer-Medium.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Medium.woff') format('woff'),
  url('/assets/fonts/Switzer-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-MediumItalic';
  src: url('/assets/fonts/Switzer-MediumItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-MediumItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Semibold';
  src: url('/assets/fonts/Switzer-Semibold.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Semibold.woff') format('woff'),
  url('/assets/fonts/Switzer-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-SemiboldItalic';
  src: url('/assets/fonts/Switzer-SemiboldItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-SemiboldItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Bold';
  src: url('/assets/fonts/Switzer-Bold.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Bold.woff') format('woff'),
  url('/assets/fonts/Switzer-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-BoldItalic';
  src: url('/assets/fonts/Switzer-BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-BoldItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Extrabold';
  src: url('/assets/fonts/Switzer-Extrabold.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Extrabold.woff') format('woff'),
  url('/assets/fonts/Switzer-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-ExtraboldItalic';
  src: url('/assets/fonts/Switzer-ExtraboldItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-ExtraboldItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-ExtraboldItalic.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Black';
  src: url('/assets/fonts/Switzer-Black.woff2') format('woff2'),
  url('/assets/fonts/Switzer-Black.woff') format('woff'),
  url('/assets/fonts/Switzer-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-BlackItalic';
  src: url('/assets/fonts/Switzer-BlackItalic.woff2') format('woff2'),
  url('/assets/fonts/Switzer-BlackItalic.woff') format('woff'),
  url('/assets/fonts/Switzer-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}


























/**
 * @license
 *
 * Font Family: General Sans
 * Designed by: Frode Helland
 * URL: https://www.fontshare.com/fonts/general-sans
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * General Sans Variable(Variable font)
 * General Sans Variable Italic(Variable font)
 * General Sans Extralight
 * General Sans Extralight Italic
 * General Sans Light
 * General Sans Light Italic
 * General Sans Regular
 * General Sans Italic
 * General Sans Medium
 * General Sans Medium Italic
 * General Sans Semibold
 * General Sans Semibold Italic
 * General Sans Bold
 * General Sans Bold Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'GeneralSans';
  src: url('/assets/fonts/GeneralSans-Variable.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Variable.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'GeneralSans';
  src: url('/assets/fonts/GeneralSans-VariableItalic.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-VariableItalic.woff') format('woff'),
  url('/assets/fonts/GeneralSans-VariableItalic.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Extralight';
  src: url('/assets/fonts/GeneralSans-Extralight.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Extralight.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-ExtralightItalic';
  src: url('/assets/fonts/GeneralSans-ExtralightItalic.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-ExtralightItalic.woff') format('woff'),
  url('/assets/fonts/GeneralSans-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Light';
  src: url('/assets/fonts/GeneralSans-Light.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Light.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-LightItalic';
  src: url('/assets/fonts/GeneralSans-LightItalic.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-LightItalic.woff') format('woff'),
  url('/assets/fonts/GeneralSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Regular';
  src: url('/assets/fonts/GeneralSans-Regular.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Regular.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-Italic';
  src: url('/assets/fonts/GeneralSans-Italic.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Italic.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Medium';
  src: url('/assets/fonts/GeneralSans-Medium.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Medium.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-MediumItalic';
  src: url('/assets/fonts/GeneralSans-MediumItalic.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-MediumItalic.woff') format('woff'),
  url('/assets/fonts/GeneralSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Semibold';
  src: url('/assets/fonts/GeneralSans-Semibold.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Semibold.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-SemiboldItalic';
  src: url('/assets/fonts/GeneralSans-SemiboldItalic.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-SemiboldItalic.woff') format('woff'),
  url('/assets/fonts/GeneralSans-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'GeneralSans-Bold';
  src: url('/assets/fonts/GeneralSans-Bold.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-Bold.woff') format('woff'),
  url('/assets/fonts/GeneralSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'GeneralSans-BoldItalic';
  src: url('/assets/fonts/GeneralSans-BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/GeneralSans-BoldItalic.woff') format('woff'),
  url('/assets/fonts/GeneralSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

