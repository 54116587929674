
:root {

  --font-family:      "GeneralSans", system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif;
  --col-foreground:   #212121;  // default font foreground color
  --background-color: #F1F1F1;
  --color-white: #FFFFFF;

  --font-weight:      400;
  --font-style:       normal;
  --font-size:        16px;
  --line-height:      unset;

  --error-color: #b71c1c;
  --valid-color: #2e7d32;

  --col-loginframe-background-left:  #FFFFFF;
  --col-loginframe-background-right: linear-gradient(180deg, #FCF9FD 0%, #F0F1FC 100%);

  --transition: 0.2s ease-in-out;

  --spacer-color: linear-gradient(#E0E1E200, #E0E1E2FF, #E0E1E20A);

  --col-required: #EB4335;

  --col-loader:    #A0AEC0;
  --col-loader-fg: #435265;

  --col-separator-dashed: #D5D6DD;

  // ----------------------------------------------------

  --col-login-subtext: #7D8398;

  // ----------------------------------------------------

  --col-alert-default-text:       #7D8398;
  --col-alert-default-background: #FAFAFB;
  --col-alert-default-border:     #D8DAE0;

  // ----------------------------------------------------

  --button-border: 1px solid rgba(125, 131, 152, 0.3);
  --button-hover-border: #25217777;
  --button-background: #FFFFFF;
  --button-color: #7D8398;
  --button-hover-color: #121212;

  --button-default-boxshadow: 0 5px 20px rgba(18, 18, 18, 0.05);

  --button-primary-color:               #FFFFFF;
  --button-primary-background:          #242F80;
  --button-primary-background-disabled: #9BA4E3;
  --button-primary-hover-background:    #121212;

  // ----------------------------------------------------

  --col-switch-circle-active:       #FFFFFF;
  --col-switch-circle-inactive:     #FFFFFF;
  --col-switch-background-active:   #242F80;
  --col-switch-background-inactive: #9E9E9E;

  --col-switch-text-inactive: #9E9E9E;
  --col-switch-text-active:   #121212;

  --col-switch-border-inactive:   #9E9E9E;
  --col-switch-border-active:     #252177;

  // ----------------------------------------------------

  --col-toggle-background:   #525880;

  // ----------------------------------------------------

  --col-radio-background-inactive:   #FFFFFF;
  --col-radio-background-active:     #FFFFFF;

  --col-radio-border-inactive:       #9E9E9E;
  --col-radio-border-active:         #252177;
  --col-radio-border-valid:          var(--valid-color);
  --col-radio-border-invalid:        var(--error-color);

  --col-radio-dot-active:            #252177;
  --col-radio-dot-valid:             var(--valid-color);
  --col-radio-dot-invalid:           var(--error-color);

  --col-radio-text-inactive:         #9E9E9E;
  --col-radio-text-active:           #121212;

  // ----------------------------------------------------

  --col-checkbox-background:            #FFFFFF;

  --col-checkbox-unchecked-border:      #9E9E9E;
  --col-checkbox-checked-border:        #252177;
  --col-checkbox-indeterminate-border:  #252177;

  --col-checkbox-border-valid:          var(--valid-color);
  --col-checkbox-border-invalid:        var(--error-color);

  --col-checkbox-text-inactive:         #9E9E9E;
  --col-checkbox-text-active:           #121212;

  // ----------------------------------------------------

  --nav-background-color: #FAFAFB;
  --nav-title-color: #2D3748;
  --nav-button-color: #7D8398;
  --nav-button-color-hover-text: #252177;
  --nav-button-color-hover-icon: #000000;
  --nav-button-active-border: #FAFAFA;
  --nav-button-active-background: #FFFFFF;
  --nav-button-active-boxshadow: rgba(0, 0, 0, 0.02);

  // ----------------------------------------------------

  --col-multiselect-tag-background: #FAFAFB;
  --col-multiselect-tag-border:     #9E9E9E;

  // ----------------------------------------------------

  --col-breadcrumb-active:    #212121;
  --col-breadcrumb-inactive:  #A0AEC0;
  --col-breadcrumb-back:      #7D8398;
  --col-breadcrumb-linkhover: #252177;
  --col-breadcrumb-fontsize:  12px;

  // ----------------------------------------------------

  --col-tab-inactive-num: #7D8398;
  --col-tab-inactive-sub: #A0AEC0;
  --col-tab-active:       #252177;

  // ----------------------------------------------------

  --col-card-background: #FFFFFF;
  --col-card-boxshadow: rgba(0, 0, 0, 0.02);

  // ----------------------------------------------------

  --col-infocard-boxshadow: rgba(0, 0, 0, 0.05);
  --col-infocard-hover-border: #25217777;
  --col-infocard-hover-text: #252177;
  --col-infocard-iconbox: #F1F1F1;
  --col-infocard-subtext: #7D8398;
  --col-infocard-circle: #DDDDDD;
  --col-infocard-header: #121212;

  // ----------------------------------------------------

  --col-input-text:           #212121;
  --col-input-background:     #FFFFFF;
  --col-input-border:         #D8DAE0;
  --col-input-boxshadow:      rgba(18, 18, 18, 0.05);
  --col-input-placeholder:    #7D8398;
  --col-input-valid:          var(--valid-color);
  --col-input-invalid:        var(--error-color);
  --col-input-border-valid:   var(--valid-color);
  --col-input-border-invalid: var(--error-color);

  --col-input-focus-border:     rgba(37, 33, 119, 0.33);
  --col-input-focus-box-shadow: rgba(18, 18,  18, 0.03);

  --col-input-unit: #7D8398;

  // ----------------------------------------------------

  --col-select-text:          #212121;
  --col-select-text-inactive: #A0AEC0;
  --col-select-placeholder:   #7D8398;
  --col-select-background:    #FFFFFF;
  --col-select-border:        #D8DAE0;
  --col-select-boxshadow:     rgba(18, 18, 18, 0.05);
  --col-select-valid:         var(--valid-color);
  --col-select-invalid:       var(--error-color);
  --col-select-x-hover:       #252177;

  --col-selectoptions-background:    #FFFFFF;
  --col-selectoptions-separator:     #F2F2F2;
  --col-selectoptions-text-default:  #A0AEC0;
  --col-selectoptions-text-selected: #212121;
  --col-selectoptions-text-hover:    #212121;

  // ----------------------------------------------------

  --col-contextmenu-background: #FFFFFF;
  --col-contextmenu-border:     #F1F1F1;
  --col-contextmenu-boxshadow:  rgba(0, 0, 0, 0.05);
  --col-contextmenu-text:       #7D8398;
  --col-contextmenu-hover:      #212121;

  --col-ellipsis-color: #A0AEC0;
  --col-ellipsis-hover: #212121;
  --col-ellipsis-open:  #212121;

  // ----------------------------------------------------

  --col-templatebuilder-page-background:         #BBBBBB;   //TODO
  --col-templatebuilder-page-border:             #000000;   //TODO
  --col-templatebuilder-block-background:        #D8DAE0;   //TODO
  --col-templatebuilder-block-border:            #888888;   //TODO
  --col-templatebuilder-block-text:              #b6b6bf;   //TODO
  --col-templatebuilder-block-hover-border:      #25217777; //TODO
  --col-templatebuilder-block-hover-background:  #FFFFFF;   //TODO
  --col-templatebuilder-button-hover:            #252177;   //TODO

  // ----------------------------------------------------

  --col-projectimagebox-background: #FFFFFF;
  --col-projectimagebox-border: #F1F1F1;
  --col-projectimagebox-img-background: #FAFAFA;
  --col-projectimagebox-img-border: #F1F1F1;
  --col-projectimagebox-filesize: #A0AEC0;
  --col-projectimagebox-hover-border: #25217777;

  // ----------------------------------------------------

  --col-tabs-header-inactive: #A0AEC0;
  --col-tabs-header-active:   #252177;

  --col-tabs-header-hover-background: #FFFFFF;
  --col-tabs-header-hover-border:     #D8DAE0;

  --col-tabs-separator:     #E8E8E8;

  // ----------------------------------------------------

  --col-menubar-background: #FFFFFF;
  --col-menubar-border:     rgba(125, 131, 152, 0.3);
  --col-menubar-boxshadow:  rgba(18, 18, 18, 0.05);
  --col-menubar-text-default:   #7D8398;
  --col-menubar-text-primary:   #252177;
  --col-menubar-text-secondary: #d9dae1;
  --col-menubar-text-disabled:  #d9dae1;

  --col-menubar-text-hover-default:   #212121;
  --col-menubar-text-hover-primary:   #252177;
  --col-menubar-text-hover-secondary: #212121;

  --col-menubar-separator: #d9dae1;

  // ----------------------------------------------------

  --col-table-header:    #A0AEC0;
  --col-table-cells:     #212121;
  --col-table-borders:   #F2F2F2;
  --col-table-row-hover: #FAFAFA;

  --col-table-bordered-background: #FFFFFF;
  --col-table-bordered-border: rgba(125, 131, 152, 0.3);

  // ----------------------------------------------------

  --col-statistics-foreground: var(--col-foreground);
  --col-statistics-title: #7D8398;
  --col-statistics-icon: #273B4A;

  // ----------------------------------------------------

  --col-imgbox-icon: #F1F1F1;
  --col-imgbox-seperator: #F1F1F1;

  // ----------------------------------------------------

}
