
main {
  width: 100%;
  height: auto;

  flex-grow: 1;

  display: flex;
  flex-direction: column;

  padding: 2rem 2rem 0;
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;


  background-color: var(--background-color);
  color: var(--col-foreground);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: var(--font-family);
}

*, ::after, ::before {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

/* override reduced-motion setting of fontawesome... */
.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;

  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);

  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);

  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);

  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);

  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.bottom-scroll-fix {
  display: block;
  height: 4rem;
  flex-shrink: 0;
}
